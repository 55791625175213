export default {
    scheme: 'london tube',
    author: 'jan t. sott',
    base00: '#231f20',
    base01: '#1c3f95',
    base02: '#5a5758',
    base03: '#737171',
    base04: '#959ca1',
    base05: '#d9d8d8',
    base06: '#e7e7e8',
    base07: '#ffffff',
    base08: '#ee2e24',
    base09: '#f386a1',
    base0A: '#ffd204',
    base0B: '#00853e',
    base0C: '#85cebc',
    base0D: '#009ddc',
    base0E: '#98005d',
    base0F: '#b06110',
};
