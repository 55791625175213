export default {
    scheme: 'mocha',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#3B3228',
    base01: '#534636',
    base02: '#645240',
    base03: '#7e705a',
    base04: '#b8afad',
    base05: '#d0c8c6',
    base06: '#e9e1dd',
    base07: '#f5eeeb',
    base08: '#cb6077',
    base09: '#d28b71',
    base0A: '#f4bc87',
    base0B: '#beb55b',
    base0C: '#7bbda4',
    base0D: '#8ab3b5',
    base0E: '#a89bb9',
    base0F: '#bb9584',
};
