export default {
    scheme: 'threezerotwofour',
    author: 'jan t. sott (http://github.com/idleberg)',
    base00: '#090300',
    base01: '#3a3432',
    base02: '#4a4543',
    base03: '#5c5855',
    base04: '#807d7c',
    base05: '#a5a2a2',
    base06: '#d6d5d4',
    base07: '#f7f7f7',
    base08: '#db2d20',
    base09: '#e8bbd0',
    base0A: '#fded02',
    base0B: '#01a252',
    base0C: '#b5e4f4',
    base0D: '#01a0e4',
    base0E: '#a16a94',
    base0F: '#cdab53',
};
