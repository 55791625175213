export default {
    scheme: 'pop',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#000000',
    base01: '#202020',
    base02: '#303030',
    base03: '#505050',
    base04: '#b0b0b0',
    base05: '#d0d0d0',
    base06: '#e0e0e0',
    base07: '#ffffff',
    base08: '#eb008a',
    base09: '#f29333',
    base0A: '#f8ca12',
    base0B: '#37b349',
    base0C: '#00aabb',
    base0D: '#0e5a94',
    base0E: '#b31e8d',
    base0F: '#7a2d00',
};
