export default function objType(obj) {
    const type = Object.prototype.toString.call(obj).slice(8, -1);
    if (type === 'Object' && typeof obj[Symbol.iterator] === 'function') {
        return 'Iterable';
    }
    if (type === 'Custom' &&
        obj.constructor !== Object &&
        obj instanceof Object) {
        // For projects implementing objects overriding `.prototype[Symbol.toStringTag]`
        return 'Object';
    }
    return type;
}
