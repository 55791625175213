import React from 'react';
import objType from './objType.js';
import JSONObjectNode from './JSONObjectNode.js';
import JSONArrayNode from './JSONArrayNode.js';
import JSONIterableNode from './JSONIterableNode.js';
import JSONValueNode from './JSONValueNode.js';
export default function JSONNode({ getItemString, keyPath, labelRenderer, styling, value, valueRenderer, isCustomNode, ...rest }) {
    const nodeType = isCustomNode(value) ? 'Custom' : objType(value);
    const simpleNodeProps = {
        getItemString,
        key: keyPath[0],
        keyPath,
        labelRenderer,
        nodeType,
        styling,
        value,
        valueRenderer,
    };
    const nestedNodeProps = {
        ...rest,
        ...simpleNodeProps,
        data: value,
        isCustomNode,
    };
    switch (nodeType) {
        case 'Object':
        case 'Error':
        case 'WeakMap':
        case 'WeakSet':
            return React.createElement(JSONObjectNode, { ...nestedNodeProps });
        case 'Array':
            return React.createElement(JSONArrayNode, { ...nestedNodeProps });
        case 'Iterable':
        case 'Map':
        case 'Set':
            return React.createElement(JSONIterableNode, { ...nestedNodeProps });
        case 'String':
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: (raw) => `"${raw}"` }));
        case 'Number':
            return React.createElement(JSONValueNode, { ...simpleNodeProps });
        case 'Boolean':
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: (raw) => (raw ? 'true' : 'false') }));
        case 'Date':
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: (raw) => raw.toISOString() }));
        case 'Null':
            return React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: () => 'null' });
        case 'Undefined':
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: () => 'undefined' }));
        case 'Function':
        case 'Symbol':
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: (raw) => raw.toString() }));
        case 'Custom':
            return React.createElement(JSONValueNode, { ...simpleNodeProps });
        default:
            return (React.createElement(JSONValueNode, { ...simpleNodeProps, valueGetter: () => `<${nodeType}>` }));
    }
}
