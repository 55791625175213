export default {
    scheme: 'shapeshifter',
    author: 'tyler benziger (http://tybenz.com)',
    base00: '#000000',
    base01: '#040404',
    base02: '#102015',
    base03: '#343434',
    base04: '#555555',
    base05: '#ababab',
    base06: '#e0e0e0',
    base07: '#f9f9f9',
    base08: '#e92f2f',
    base09: '#e09448',
    base0A: '#dddd13',
    base0B: '#0ed839',
    base0C: '#23edda',
    base0D: '#3b48e3',
    base0E: '#f996e2',
    base0F: '#69542d',
};
