export default {
    scheme: 'railscasts',
    author: 'ryan bates (http://railscasts.com)',
    base00: '#2b2b2b',
    base01: '#272935',
    base02: '#3a4055',
    base03: '#5a647e',
    base04: '#d4cfc9',
    base05: '#e6e1dc',
    base06: '#f4f1ed',
    base07: '#f9f7f3',
    base08: '#da4939',
    base09: '#cc7833',
    base0A: '#ffc66d',
    base0B: '#a5c261',
    base0C: '#519f50',
    base0D: '#6d9cbe',
    base0E: '#b6b3eb',
    base0F: '#bc9458',
};
