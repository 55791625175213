export default {
    scheme: 'colors',
    author: 'mrmrs (http://clrs.cc)',
    base00: '#111111',
    base01: '#333333',
    base02: '#555555',
    base03: '#777777',
    base04: '#999999',
    base05: '#bbbbbb',
    base06: '#dddddd',
    base07: '#ffffff',
    base08: '#ff4136',
    base09: '#ff851b',
    base0A: '#ffdc00',
    base0B: '#2ecc40',
    base0C: '#7fdbff',
    base0D: '#0074d9',
    base0E: '#b10dc9',
    base0F: '#85144b',
};
