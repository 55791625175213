export default {
    scheme: 'atelier seaside',
    author: 'bram de haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/seaside/)',
    base00: '#131513',
    base01: '#242924',
    base02: '#5e6e5e',
    base03: '#687d68',
    base04: '#809980',
    base05: '#8ca68c',
    base06: '#cfe8cf',
    base07: '#f0fff0',
    base08: '#e6193c',
    base09: '#87711d',
    base0A: '#c3c322',
    base0B: '#29a329',
    base0C: '#1999b3',
    base0D: '#3d62f5',
    base0E: '#ad2bee',
    base0F: '#e619c3',
};
