export default {
    scheme: 'green screen',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#001100',
    base01: '#003300',
    base02: '#005500',
    base03: '#007700',
    base04: '#009900',
    base05: '#00bb00',
    base06: '#00dd00',
    base07: '#00ff00',
    base08: '#007700',
    base09: '#009900',
    base0A: '#007700',
    base0B: '#00bb00',
    base0C: '#005500',
    base0D: '#009900',
    base0E: '#00bb00',
    base0F: '#005500',
};
