export default {
    scheme: 'marrakesh',
    author: 'alexandre gavioli (http://github.com/alexx2/)',
    base00: '#201602',
    base01: '#302e00',
    base02: '#5f5b17',
    base03: '#6c6823',
    base04: '#86813b',
    base05: '#948e48',
    base06: '#ccc37a',
    base07: '#faf0a5',
    base08: '#c35359',
    base09: '#b36144',
    base0A: '#a88339',
    base0B: '#18974e',
    base0C: '#75a738',
    base0D: '#477ca1',
    base0E: '#8868b3',
    base0F: '#b3588e',
};
