export default {
    scheme: 'atelier heath',
    author: 'bram de haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/heath)',
    base00: '#1b181b',
    base01: '#292329',
    base02: '#695d69',
    base03: '#776977',
    base04: '#9e8f9e',
    base05: '#ab9bab',
    base06: '#d8cad8',
    base07: '#f7f3f7',
    base08: '#ca402b',
    base09: '#a65926',
    base0A: '#bb8a35',
    base0B: '#379a37',
    base0C: '#159393',
    base0D: '#516aec',
    base0E: '#7b59c0',
    base0F: '#cc33cc',
};
